/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';

import { SearchLayout } from '@/components/page-layouts';
import SearchPage from '@/components/SearchPage/SearchPage';
import { GoogleMapProvider } from '@/lib/context/GoogleMaps/GoogleMapProvider';

export const Search = () => {
  const { t } = useTranslation('search');

  return (
    <>
      <GoogleMapProvider>
        <Head>
          <title>{`Kingbee Vans | ${t('searchTitle')}`}</title>
          <meta
            key="title"
            property="og:title"
            content={`Kingbee Vans | ${t('searchTitle')}`}
          />
          <meta name="description" content={t('metaDescription')} />
        </Head>
        <SearchPage />
      </GoogleMapProvider>
    </>
  );
};

Search.namespace = 'SearchPage';

Search.Layout = SearchLayout;

interface SSProps {
  locale: string;
}

export const getServerSideProps = async ({ locale }: SSProps) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      'common',
      'maintenance',
      'messages',
      'org_menu',
      'search',
      'reservations',
      'ev',
    ])),
  },
});

export default Search;
